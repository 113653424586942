import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardPreviewComponent } from './components/card-preview/card-preview.component';
import { CardNewComponent } from './components/card-new/card-new.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { SelectableItemComponent } from './components/selectable-item/selectable-item.component';
import { SelectableItemDetailComponent } from './components/selectable-item-detail/selectable-item-detail.component';
import { SelectableSubitemComponent } from './components/selectable-item/components/selectable-subitem/selectable-subitem.component';
import { SidebarModule } from 'primeng/sidebar';
import { MerchPreviewComponent } from './components/merch-preview/merch-preview.component';
import { CardModule } from 'primeng/card';
import { TagModule } from 'primeng/tag';
import { CarouselModule } from 'primeng/carousel';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [
    CardPreviewComponent,
    CardNewComponent,
    SelectableItemComponent,
    SelectableItemDetailComponent,
    SelectableSubitemComponent,
    MerchPreviewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputMaskModule,
    ButtonModule,
    MessagesModule,
    MessageModule,
    SidebarModule,
    CardModule,
    TagModule,
    CarouselModule,
    DropdownModule,
    TranslateModule,
    SkeletonModule,
    InputTextareaModule
  ],
  exports: [
    CardPreviewComponent,
    CardNewComponent,
    SelectableItemComponent,
    SelectableItemDetailComponent,
    SelectableSubitemComponent,
    MerchPreviewComponent,
    TranslateModule,
  ],
})
export class SharedModule {}
