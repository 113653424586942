import { Component, LOCALE_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { State, Store } from '@ngrx/store';
import { UserConfigured } from './core/model/user-configured.interface';
import {
  loginSuccess,
  organizationSuccess,
  tenantSuccess,
} from './core/store/actions';
import { CoreService } from './core/services/core.service';
import { filter } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { TenantInfo } from './core/model/tenant-info.interface';
import { environment } from 'src/environments/environment';
import { MetaService } from './shared/service/meta.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

registerLocaleData(localeEs, 'es');

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'es' }]
})
export class AppComponent {
  name: string;

  constructor(
    private coreService: CoreService,
    private store: Store,
    private router: Router,
    private cookieService: CookieService,
    private metaService: MetaService,
    private renderer2: Renderer2,
    private translate: TranslateService,
    private primengConfig: PrimeNGConfig
  ) {

    this.setInitialLanguage();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
      });
    
  }

  ngOnInit() {
    const url = new URL(window.location.href);

    if (url.hostname !== environment.domain && url.hostname !== 'localhost') {
      this.coreService
        .getExternalTenantInfo()
        .subscribe((response: TenantInfo) => {
          this.store.dispatch(tenantSuccess({ tenant: response.domain }));
          this.store.dispatch(organizationSuccess({ organization: response }));
        });
    } else {
      const pathParts = url.pathname.split('/');

      if (pathParts.length === 0) {
        this.router.navigate['not-found'];
      }

      const tenant = pathParts[1];

      const access_token = this.cookieService.get('access_token');

      if (
        tenant &&
        !tenant.startsWith('auth') &&
        !tenant.startsWith('not-found') &&
        !tenant.startsWith('directory') &&
        !tenant.startsWith('payment-success')
      ) {
        if (access_token) {
          this.coreService
            .isUserConfigured()
            .subscribe((response: UserConfigured) => {
              if (response.loggedIn) {
                this.store.dispatch(
                  loginSuccess({
                    user: { name: response.name, id: response.id },
                  })
                );
              }
            });
        }

        this.store.dispatch(tenantSuccess({ tenant: tenant }));

        if (tenant) {
          this.coreService.getTenantInfo().subscribe((response: TenantInfo) => {
            this.metaService.setOrganizationSchema(this.renderer2, response);
            this.store.dispatch(
              organizationSuccess({ organization: response })
            );
          });
        }
      } else {
        if (localStorage.getItem('tenantId') !== undefined) {
          this.store.dispatch(tenantSuccess(localStorage.getItem('tenantId')));

          if (access_token) {
            this.coreService
              .isUserConfigured()
              .subscribe((response: UserConfigured) => {
                if (response.loggedIn) {
                  this.store.dispatch(
                    loginSuccess({
                      user: { name: response.name, id: response.id },
                    })
                  );
                }
              });
          }

          this.coreService.getTenantInfo().subscribe((response) => {
            this.metaService.setOrganizationSchema(this.renderer2, response);
            this.store.dispatch(
              organizationSuccess({ organization: response })
            );
          });
        }
      }
    }
    
  }

  setInitialLanguage() {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.translate.setDefaultLang(savedLanguage);
      this.translate.use(savedLanguage);
      this.setPrimeNGLocale(savedLanguage);
    } else {
      const detectedLanguage = this.detectLocale();
      this.translate.setDefaultLang(detectedLanguage);
      this.translate.use(detectedLanguage);
      localStorage.setItem('selectedLanguage', detectedLanguage);
      this.setPrimeNGLocale(detectedLanguage);
    }
  
    // Subscribe to language changes to update PrimeNG locale dynamically
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setPrimeNGLocale(event.lang);
    });
  }
  
  private setPrimeNGLocale(lang: string) {
    this.translate.get('primeng').subscribe((primengLocale: any) => {
      this.primengConfig.setTranslation(primengLocale);
    });
  }
  

  private detectLocale(): string {
    const userLang = navigator.language || navigator.languages[0];
    const supportedLangs = ['es', 'en', 'pt'];
  
    if (supportedLangs.includes(userLang)) {
      return userLang;
    } else if (supportedLangs.includes(userLang.split('-')[0])) {
      return userLang.split('-')[0];
    } else {
      return 'es';
    }
  }
  
}
